import React, { useState, useEffect } from 'react';
import './App.css';  // For your styles
import Navbar from './components/NavBar';

// Component for each project card
const ProjectCard = ({ project }) => {
    return (
        <div className="project-card" onClick={() => window.location.href = project.link}>
            <h2>{project.name}</h2>
            <p>{project.description}</p>
        </div>
    );
};

// Main Component for all projects
function App() {

  const [isVisible, setIsVisible] = useState(false); // Card visibility
  const [showIcon, setShowIcon] = useState(true); // Show the scroll icon

  const projects = [
    {
        name: "broad2broad.com",
        description: "A broadcasting service platform.",
        link: "https://broad2broad.com"
    },
    {
        name: "cutsimulation.com",
        description: "Simulation software for cutting technologies.",
        link: "https://cutsimulation.com"
    }
  ];

  const handleArrowClick = () => {
    setIsVisible(true);
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsVisible(false);
    } else if (window.scrollY > 100) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <Navbar />
      {!isVisible && (
        <div className="scroll-icon" onClick={handleArrowClick}>
          ↓
        </div>
      )}
      <main className="projects-container" style={styles.main}>
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </main>

      <footer>
        <div className="footer-info">
          <p>Forensik.cc: Research & Pricing.</p>
          <p>Swarm-networks.com: Networking & Tools Integration.</p>
        </div>
      </footer>
    </div>
  );
}

const styles = {
  main: {
    marginTop: '100px',
    padding: '20px',
    textAlign: 'center',
  }
};

export default App;
